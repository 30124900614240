<template>
  <div class="col-12 c g">
    <div class="card card-body">
        <div class="col-12">
            <button class="btn btn-sm btn-primary" @click="seenall()">
                <i class="fa fa-eye"></i>
                تعيين الكل "تمت رؤيتها"
            </button>
            <br><br>
        </div>
        <div class="col-12 table-responsive" style="min-height: 500px">
            <table class="table table-hover table-bordered table-sm">
                <thead>
                    <th>
                        بواسطة
                    </th>
                    <th>
                        الطالب
                    </th>
                    <th>
                        الفصل
                    </th>
                    <th>
                        التاريخ
                    </th>
                    <th>
                        نوع المشكلة
                    </th>
                    <th>
                        تكرار المشكلة
                    </th>
                    <th>
                        وصف المشكلة
                    </th>
                    <th>
                        الاجراء المتخذ
                    </th>
                    <th>
                        الحالة	
                    </th>
                    <th>
                        رد الوكيل
                    </th>
                    <th>
                        خيارات
                    </th>
                </thead>
                <tbody>
                    <tr v-for="item in forwards" :key="item._id">
                        <td>
                            {{ item.teacher_name }}
                        </td>
                        <td>
                            {{ item.student_name }}
                        </td>
                        <td>
                            {{ item.classname }} - {{ item.classroom }}
                        </td>
                        <td>
                            {{ item.date }}
                        </td>
                        <td>
                            {{ item.type }}
                        </td>
                        <td>
                            {{ item.repeat }}
                        </td>
                        <td>
                            {{ item.details }}
                        </td>
                        <td>
                            {{ item.opinion }}
                        </td>
                        <td>
                            <span v-if="item.second_viewed" class="text-success">
                                <i class="fa fa-eye"></i>
                                تمت رؤيتها<br>الرد: {{ item.second_reply }}
                            </span>
                            <span v-if="!item.second_viewed" class="text-danger">
                                <i class="fa fa-clock-o"></i>
                                في الانتظار	
                            </span>
                            <span v-if="item.direct_from_teacher"><br>من المعلم</span>
                        </td>
                        <td>
                            {{ item.response }}
                        </td>
                        <td>
                            <b-dropdown dropleft style="width: 100%"
                                id="dropdown-1"
                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                text="خيارات"
                                variant="secondary"
                                size="sm"
                            >
                                <b-dropdown-item @click="seen(item._id)" v-if="!item.second_viewed">
                                    <i class="fa fa-eye"></i>
                                    تمت رؤيتها
                                </b-dropdown-item>
                                <b-dropdown-item @click="reply(item._id, item.second_reply)">
                                    <i class="fa fa-reply"></i>
                                    رد على المشكلة
                                </b-dropdown-item>
                                <b-dropdown-item @click="print(item)">
                                    <i class="fa fa-print"></i>
                                    طباعة
                                </b-dropdown-item>
                            </b-dropdown>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <b-modal id="message" title="رسالة لولي الامر" hide-footer>
        <div class="form-group">
          <label for="">الرسالة</label>
          <textarea class="form-control" v-model="message" rows="3" @change="sendmessage(itemx)" @keyup="sendmessage(itemx)"></textarea>
        </div>
        <div class="g border">
            {{ finalmessage }}
        </div>
        <div class="row">
            <div class="col-12 col-lg-4 g">
                <button class="btn btn-success btn-block" @click="send('whatsapp')">
                    <i class="fa fa-whatsapp"></i>
                        واتس تلقائي
                </button>
            </div>
            <div class="col-12 col-lg-4 g">
                <button class="btn btn-success btn-block" @click="send('wa')">
                    <i class="fa fa-whatsapp"></i>
                    اداة المتصفح
                </button>
            </div>
            <div class="col-12 col-lg-4 g">
                <button class="btn btn-primary btn-block"  @click="send('sms')">
                    <i class="fa fa-mobile"></i>
                    SMS
                </button>
            </div>
        </div>
    </b-modal>
  </div>
</template>

<script>
import { BModal, VBModal, BFormCheckbox, BDropdown, BDropdownItem } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
export default {
    components: {
        BFormCheckbox,
        BModal,
        BDropdown,
        BDropdownItem,
    },
    directives: {
        Ripple,
        "b-modal": VBModal,
    },
    data(){
        return {
            user: JSON.parse(localStorage.getItem('user')),
            forwards: [],
            message: `ولي امر الطالب {name}
تم تسجيل مشكلة لابنكم
وصف المشكلة {details}`,
            phones: [],
            finalmessage: "",
            itemx: {}
        }
    },
    created(){ 
        if(!checkPer("students|forwards2")){
            this.$router.push('/NotPermitted')
            return false;
        }
        this.getFo();
    },
    methods: {
        sendmessage(item){
            this.itemx = item;
            if(item.phones){
                this.phones = item.phones
            }
            this.finalmessage = this.message.replace("{name}", item.student_name).replace("{details}", item.details)
        },
        getFo(){
            var g = this;
            $.post(api + '/user/lessons/forward/second/list', {
                jwt: this.user.jwt
            }).then(function(r){
                g.loading = false
                if(r.status != 100){
                    alert(r.response)
                }else{
                    g.forwards = r.response;
                }
            }).fail(function(){
            })
        },
        print(item){
            var divToPrint=document.getElementById("studentstable");
            var newWin= window.open("");
            newWin.document.write(`
            <style>
            html, body{
                direction: rtl
            }
            .hidemonprint{
                display:none;   
            }
            table, td, th {
            border: 1px solid;
            }

            table {
            width: 100%;
            border-collapse: collapse;
            }
            </style>
            <center>
        <h4 style='background: #dddd'>تحويل طالب لوكيل شؤون الطلاب</h4>
        المكرم /            وكيل شؤون الطلاب                         وفقه الله
        <br>
        السلام عليكم ورحمة الله وبركاته   وبعد
        <br>
        بناء على واجباتنا الـتـربـويـة والتعليمية تجاه أبنائنا الطلاب وعلى متابعتنا المستمرة لهم ، فقد قمنا برصـد الحالة الموضح بياناتها أعلاه وبذلنا حيالها الجهود المستطاعة . إلا أننا نرى أنها تحتاج لمزيـد مـن بـرامـج الـتـوجـيـه والإرشاد الخاصة .. لهذا فإننا نحيلها إليكم لاتخاذ ما ترونه مناسبا . والله يحفظكم ويرعـاكـم ،،
    </center>
    <table style="border:0.75pt solid #000000; -aw-border:0.5pt single; -aw-border-insideh:0.5pt single #000000; -aw-border-insidev:0.5pt single #000000; border-collapse:collapse">
  <tbody>
    <tr style="height:3.5pt">
      <td style="width:143.35pt; border-left:0.75pt solid #000000; border-bottom:0.75pt solid #000000; padding-right:5.03pt; padding-left:5.03pt; vertical-align:middle; -aw-border-bottom:0.5pt single; -aw-border-left:0.5pt single">
        <p dir="rtl" style="margin-top:0pt; margin-bottom:0pt; font-size:10pt">
          <span style="font-family:ae_AlMohanad">اسم المعلم محيل الحالة</span>
        </p>
      </td>
      <td style="width:362.35pt; border-right:0.75pt solid #000000; border-bottom:0.75pt solid #000000; padding-right:5.03pt; padding-left:5.03pt; vertical-align:middle; -aw-border-bottom:0.5pt single; -aw-border-right:0.5pt single">
        <p dir="rtl" style="margin-top:0pt; margin-bottom:0pt; font-size:10pt">
          <span style="-aw-import:ignore">${item.teacher_name}</span>
        </p>
      </td>
    </tr>
    <tr style="height:3.5pt">
      <td style="width:143.35pt; border-top:0.75pt solid #000000; border-left:0.75pt solid #000000; border-bottom:0.75pt solid #000000; padding-right:5.03pt; padding-left:5.03pt; vertical-align:middle; -aw-border-bottom:0.5pt single; -aw-border-left:0.5pt single; -aw-border-top:0.5pt single">
        <p dir="rtl" style="margin-top:0pt; margin-bottom:0pt; font-size:10pt">
          <span style="font-family:ae_AlMohanad">اسم الطلاب المحولين</span>
        </p>
      </td>
      <td style="width:362.35pt; border-top:0.75pt solid #000000; border-right:0.75pt solid #000000; border-bottom:0.75pt solid #000000; padding-right:5.03pt; padding-left:5.03pt; vertical-align:middle; -aw-border-bottom:0.5pt single; -aw-border-right:0.5pt single; -aw-border-top:0.5pt single">
        <p dir="rtl" style="margin-top:0pt; margin-bottom:0pt; font-size:10pt">
          <span style="-aw-import:ignore">${item.student_name}</span>
        </p>
      </td>
    </tr>
    <tr style="height:3.5pt">
      <td style="width:143.35pt; border-top:0.75pt solid #000000; border-left:0.75pt solid #000000; border-bottom:0.75pt solid #000000; padding-right:5.03pt; padding-left:5.03pt; vertical-align:middle; -aw-border-bottom:0.5pt single; -aw-border-left:0.5pt single; -aw-border-top:0.5pt single">
        <p dir="rtl" style="margin-top:0pt; margin-bottom:0pt; font-size:10pt">
          <span style="font-family:ae_AlMohanad">نوع المشكلة</span>
        </p>
      </td>
      <td style="width:362.35pt; border-top:0.75pt solid #000000; border-right:0.75pt solid #000000; border-bottom:0.75pt solid #000000; padding-right:5.03pt; padding-left:5.03pt; vertical-align:middle; -aw-border-bottom:0.5pt single; -aw-border-right:0.5pt single; -aw-border-top:0.5pt single">
        <p dir="rtl" style="margin-top:0pt; margin-bottom:0pt; font-size:10pt">
          <span style="-aw-import:ignore">${item.type}</span>
        </p>
      </td>
    </tr>
    <tr style="height:3.5pt">
      <td style="width:143.35pt; border-top:0.75pt solid #000000; border-left:0.75pt solid #000000; border-bottom:0.75pt solid #000000; padding-right:5.03pt; padding-left:5.03pt; vertical-align:middle; -aw-border-bottom:0.5pt single; -aw-border-left:0.5pt single; -aw-border-top:0.5pt single">
        <p dir="rtl" style="margin-top:0pt; margin-bottom:0pt; font-size:10pt">
          <span style="font-family:ae_AlMohanad">وصف المشكلة</span>
        </p>
      </td>
      <td style="width:362.35pt; border-top:0.75pt solid #000000; border-right:0.75pt solid #000000; border-bottom:0.75pt solid #000000; padding-right:5.03pt; padding-left:5.03pt; vertical-align:middle; -aw-border-bottom:0.5pt single; -aw-border-right:0.5pt single; -aw-border-top:0.5pt single">
        <p dir="rtl" style="margin-top:0pt; margin-bottom:0pt; font-size:10pt">
          <span style="-aw-import:ignore">${item.details}</span>
        </p>
      </td>
    </tr>
    <tr style="height:3.5pt">
      <td style="width:143.35pt; border-top:0.75pt solid #000000; border-left:0.75pt solid #000000; border-bottom:0.75pt solid #000000; padding-right:5.03pt; padding-left:5.03pt; vertical-align:middle; -aw-border-bottom:0.5pt single; -aw-border-left:0.5pt single; -aw-border-top:0.5pt single">
        <p dir="rtl" style="margin-top:0pt; margin-bottom:0pt; font-size:10pt">
          <span style="font-family:ae_AlMohanad">تكرار المشكلة</span>
        </p>
      </td>
      <td style="width:362.35pt; border-top:0.75pt solid #000000; border-right:0.75pt solid #000000; border-bottom:0.75pt solid #000000; padding-right:5.03pt; padding-left:5.03pt; vertical-align:middle; -aw-border-bottom:0.5pt single; -aw-border-right:0.5pt single; -aw-border-top:0.5pt single">
        <p dir="rtl" style="margin-top:0pt; margin-bottom:0pt; font-size:10pt">
          <span style="-aw-import:ignore">1</span>
        </p>
      </td>
    </tr>
    <tr style="height:3.5pt">
      <td style="width:143.35pt; border-top:0.75pt solid #000000; border-left:0.75pt solid #000000; padding-right:5.03pt; padding-left:5.03pt; vertical-align:middle; -aw-border-left:0.5pt single; -aw-border-top:0.5pt single">
        <p dir="rtl" style="margin-top:0pt; margin-bottom:0pt; font-size:10pt">
          <span style="font-family:ae_AlMohanad">رأي المعلم لعلاج المشكلة</span>
        </p>
      </td>
      <td style="width:362.35pt; border-top:0.75pt solid #000000; border-right:0.75pt solid #000000; padding-right:5.03pt; padding-left:5.03pt; vertical-align:middle; -aw-border-right:0.5pt single; -aw-border-top:0.5pt single">
        <p dir="rtl" style="margin-top:0pt; margin-bottom:0pt; font-size:10pt">
          <span style="-aw-import:ignore">${item.opinion}</span>
        </p>
      </td>
    </tr>
  </tbody>
</table><center>
    <h4 style='background: #dddd'>
    إقرار الطالب
    </h4>
    </center>
    <p>
    اقر أنا الطالب /   ${item.student_name}&nbsp;&nbsp;&nbsp;
<br>
        بصحة البيانات السابقة المحولة من المعلم وأتعهد بعـد الـتـكـرار وإذا حصل خلاف ذلك فأن لإدارة المدرسة اتخاذ الإجراء المناسب حسب اللوائح والأنظمة  وعلى ذلك جرى التوقيع.
        <br>
        اليــــــــوم : …………………
        <br>
        التــــاريــخ :    &nbsp;&nbsp;&nbsp;  /    &nbsp;&nbsp;&nbsp;  /   &nbsp;&nbsp;&nbsp;  144هـ
        <br>
        توقيع الطالب : …………………


    </p>
    <center>
    <h4 style='background: #dddd'>
    ما تم حيال الطالب : ( خاص بوكيل شؤون الطلاب)
    </h4>
    </center>
    <ul>
        <li>
        ${item.opinion}
        </li>
    </ul>
    تمت إحالته إلى الموجه الطلابي .
<br>
    <br>
وكيل شؤون الطلاب : …………………………………..  التوقيع : …………………………………..
<br>

<center>
<h4 style='background: #dddd'>
الإجراءات العلاجية والخدمات الإرشادية المقدمة للطالب من قبل الموجه الطلابي
<br>
<br><br>
    </h4>
    </center>
    المكرم المعلم الفاضل /     1          وفقه الله
    <br>
نفيدكم بأنه قد تم التعامل مع مشكلة الطالب الذي أحـلـتـمـوه إلينا وفق البيانات المسجلة بالخلف ، حيث تمت معالجة وضع
<br>
الطالب من خلال تنفيذ الإجراءات العلاجية وتقديم الخدمات الإرشادية الموضحة أعلاه . وتمت إعادة تحويله إليكم لمتابعته
<br>
وموافاتنا بتقرير عن مدى تحسن حالة الطالب خلال الأيام القادمة.
<br>
وتـقـبـلـوا فـائـق الـتـقـديـر والاحـتـرام
<br>
الموجه الطلابي&nbsp; : …………………………………..  التوقيع : …………………………………..
<br>
<center>
<h4 style='background: #dddd'>
افادة المعلم
    </h4>
    </center>
    <br>
    <p>
    نفيدكم بأن المشكلة المرصودة على الطالب:     ما زالت موجودة       تحسنت قليلاً       تحسنت كثيراً      اختفت تماماً

 <br>

تم اقفال الحالة في يوم : …………………………………..   الموافق :  &nbsp; &nbsp;   /   &nbsp; &nbsp;  / &nbsp; &nbsp;   144هـ

<br>
الموجه الطلابي : …………………………………..  التوقيع : …………………………………..
    </p>
            `);
            newWin.document.close()
            newWin.print();
        },
        seen(id){
            var g = this;
            $.post(api + '/user/lessons/forward/second/seen', {
                jwt: this.user.jwt,
                id: id
            }).then(function(r){
                g.loading = false
                if(r.status != 100){
                    alert(r.response)
                }else{
                    g.getFo()
                }
            }).fail(function(){
            })
        },
        seenall(){
            var g = this;
            if(!confirm("متاكد من تعيين الكل انه تمت رؤيتها؟")){return false;}
            $.post(api + '/user/lessons/forward/second/seen-all', {
                jwt: this.user.jwt
            }).then(function(r){
                g.loading = false
                if(r.status != 100){
                    alert(r.response)
                }else{
                    g.getFo()
                }
            }).fail(function(){
            })
        },
        reply(id, reply){
            var g = this;
            var reply = prompt("الرد", reply ?? "")
            if(reply){
                $.post(api + '/user/lessons/forward/second/reply', {
                    jwt: this.user.jwt,
                    id: id,
                    reply: reply
                }).then(function(r){
                    g.loading = false
                    if(r.status != 100){
                        alert(r.response)
                    }else{
                        g.getFo()
                    }
                }).fail(function(){
                })
            }
        },
    }
}
</script>

<style>
.classroom{
    padding: 15px; 
    border: 2px solid #ddd;
    border-radius: 5px;
}
.classroom:hover{
    background: #fafafa !important;
    cursor: pointer;
    border: 2px solid #333;
}
</style>